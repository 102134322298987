import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import moleculeInfo from './modules/moleculeInfo'
import propertieRecord from './modules/propertieRecord'
import references from './modules/references'
import moleculeInfoAuditRecord from './modules/moleculeInfoAuditRecord'
import propertieRecordAuditRecord from './modules/propertieRecordAuditRecord'
import articleType from './modules/articleType'
import article from './modules/article'
import networkModel from './modules/networkModel'
import networkModelAuditRecord from './modules/networkModelAuditRecord'
import dataDisplay from './modules/dataDisplay'
import logLogin from './modules/logLogin'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    //保存token
    //我们需要让该对象要么等于从本地存储获取到的token对象，或者空对象
    //需要将Token对象字符串装换为token对象
    tokenObj: JSON.parse(localStorage.getItem('token')) || null,
    //刷新Token,如果需要的话可以使用，如果不需要的话可以忽略
    refreshToken: JSON.parse(localStorage.getItem('refreshToken')) || null,
  },
  mutations: {
    //设置token
    setTokenInfo(state, obj) {
      state.tokenObj = obj
      localStorage.setItem('token', JSON.stringify(obj))

    },
    //移除token
    removeTokenInfo(state){
      state.tokenObj = null
      localStorage.removeItem('token')
    },
    //设置刷新token
    setRefreshToken(state, obj) {
      state.refreshToken = obj
      localStorage.setItem('refreshToken', JSON.stringify(obj))
    },
  },
  actions: {
  },
  modules: {
    user,
    moleculeInfo,
    propertieRecord,
    references,
    moleculeInfoAuditRecord,
    propertieRecordAuditRecord,
    articleType,
    article,
    networkModel,
    networkModelAuditRecord,
    dataDisplay,
    logLogin
  }
})
export default store
