import request from '../../util/request'
//用户登录
function login(data) {
    return request({ 
        url: '/auth/login' ,
        method:'post',
        data:{
            account:data.account,
            password:data.password,
            captcha:data.validcode,
            verify_value:data.verify_value
        }
    })
}
//分页查询用户信息列表
function select(data) {
    return request({ 
        url: '/user/select/'+data.pagesize+'/'+data.pagenum ,
        method:'get'
    })
}
function selectBasicInfo(data) {
    return request({ 
        url: '/user/select/basicinfo' ,
        method:'post',
        data
    })
}
//添加单个用户信息
function add(data) {
    return request({ 
        url: '/user/add' ,
        method:'post',
        data
    })
}
//编辑用户信息
function edit(data) {
    return request({ 
        url: '/user/edit' ,
        method:'post',
        data
    })
}
//删除单个用户信息
function del(data) {
    return request({ 
        url: '/user/delete' ,
        method:'post',
        data
    })
}
//获取验证码
function getVolidCode(){
	return request({
		url: '/auth/get/captcha' ,
		method: 'get'
	})
}
export{
    login,
    getVolidCode,
    select,
    add,
    selectBasicInfo,
    edit,
    del
}