import request from '../../util/request'
//分页查询分子条目信息列表
function select(data) {
    return request({ 
        url: '/moleculeinfo/select/'+data.pagesize+'/'+data.pagenum ,
        method:'get'
    })
}
//添加分子条目信息
function add(data) {
    return request({ 
        url: '/moleculeinfo/add' ,
        method:'post',
        data
    })
}
//编辑分子条目信息
function edit(data) {
    return request({ 
        url: '/moleculeinfo/edit' ,
        method:'post',
        data
    })
}
//删除分子条目相关文件
function delFile(data) {
    return request({ 
        url: '/moleculeinfo/delete/file' ,
        method:'post',
        data
    })
}
//查看分子条目相关文件
function selectFile(data) {
    return request({ 
        url: '/moleculeinfo/select/file' ,
        method:'post',
        data
    })
}
//下载分子条目相关文件
function downloadFile(data) {
    return request({ 
        url: '/file/download?url='+data ,
        method:'get',
    })
}
export{
    select,
    add,
    edit,
    delFile,
    selectFile,
    downloadFile
}