import request from '../../util/request'
function select() {
    return request({ 
        url: '/article/type/select',
        method:'get'
    })
}
function add(data) {
    return request({ 
        url: '/article/type/add',
        method:'post',
        data
    })
}
function edit(data) {
    return request({ 
        url: '/article/type/edit',
        method:'post',
        data
    })
}
function del(data) {
    return request({ 
        url: '/article/type/delete',
        method:'post',
        data
    })
}
export{
    select,
    add,
    edit,
    del,
}