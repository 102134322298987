import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    hidden: true,//不在导航列表中显示
    redirect: '/login',  //当路由未匹配时重定向，可作初始划显示页面设置
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/index')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home/index'),
    redirect: '/homePage',
    children: [
      {
        path: "/homePage",
        name: "HomePage",
        component: () => import('../views/HomePage/index')
      },
      {
        path: "/userManage",
        name: "UserManage",
        component: () => import('../views/UserManage/index')
      },
      {
        path: "/moleculeInfoManage",
        name: "MoleculeInfoManage",
        component: () => import('../views/MoleculeInfoManage/index')
      },
      {
        path: "/moleculeInfoAuditRecordSelect",
        name: "MoleculeInfoAuditRecordSelect",
        component: () => import('../views/MoleculeInfoManage/MoleculeInfoAuditRecordSelect/index')
      }
      , {
        path: "/propertieRecordNotAuditRecordManage",
        name: "PropertieRecordNotAuditRecordManage",
        component: () => import('../views/PropertieRecordNotAuditRecordManage/index')
      }
      , {
        path: "/propertieRecordAlreadyAuditRecordManage",
        name: "PropertieRecordAlreadyAuditRecordManage",
        component: () => import('../views/PropertieRecordAlreadyAuditRecordManage/index')
      }, {
        path: "/articleManage",
        name: "ArticleManage",
        component: () => import('../views/ArticleManage')
      }, {
        path: "/networkModelManage",
        name: "NetworkModelManage",
        component: () => import('../views/NetworkModelManage')
      }, {
        path: "/logLogin",
        name: "LogLogin",
        component: () => import('../views/LogManage/Login/index')
      }

    ]
  }
]

const router = new VueRouter({
  mode: 'hash', // 去掉#，需要路由模式改为history
  routes
})

export default router
