import {add} from '../../api/networkModelAuditRecord'
import store from '../../store'
const actions = {
    add({ commit },data){
        return new Promise((resolve, reject) => {
            add(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
}
export default {
    namespaced: true,
    actions
}