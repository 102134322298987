import { select} from '../../api/logLogin'
const actions = {
    select({ commit },data){
        return new Promise((resolve, reject) => {
            select(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
}
export default {
    namespaced: true,
    actions
}