import { select,add,edit,del } from '../../api/articleType'
const actions = {
    select({ commit }){
        return new Promise((resolve, reject) => {
            select().then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    add({ commit },data){
        return new Promise((resolve, reject) => {
            add(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    edit({ commit },data){
        return new Promise((resolve, reject) => {
            edit(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    del({ commit },data){
        return new Promise((resolve, reject) => {
            del(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    }
}
export default {
    namespaced: true,
    actions
}