import request from '../../util/request'
//添加分子条目审核记录
function add(data) {
    return request({ 
        url: '/moleculeinfoauditrecord/add' ,
        method:'post',
        data
    })
}
//查看分子条目审核记录
function select(data){
    return request({ 
        url: '/moleculeinfoauditrecord/select/'+data.pagesize+'/'+data.pagenum ,
        method:'get'
    })
}
export{
    add,
    select
}