
import Vue from 'vue'
import axios from 'axios'
import Vueaxios from 'vue-axios'
import router from '../router'
import store from '../store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { Message } from 'element-ui'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.timeout = 3000;

Vue.use(Vueaxios, axios)
//为了使全局.vue文件可以访问到axios，可以使用vue-axios。如果不用vue-axios，则可使用原型链进行加载，如下
//Vue.prototype.$axios = axios

//克隆一个新的，没有拦截器的axios对象，在需要刷新token时需要
// const instance = axios.create({
//   baseURL:global.BASE_URL
// });

// 添加请求拦截器
axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  //开启进度条
  NProgress.start()
  //先判断有没有token，防止在登录时也携带token
  if (store.state.tokenObj) {
    //有token的话就放入请求头
    config.headers.Authorization = ' Bearer ' + store.state.tokenObj.token
  }
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  if(response.data.code == 20101 ||response.data.code == 20102 ){
    NProgress.done()
      store.commit('removeTokenInfo') //清除token信息
      Message.error(response.data.msg)
      router.push("/login"); //跳转到登录页面
      return
  }else if(response.data.code != 200){
    if(response.data.msg != undefined){
      Message.error(response.data.msg)
    }
  }
  // 对响应数据做点什么
  NProgress.done()
  return response;
}, async function (error) {
  console.log(error);
  // 对响应错误做点什么

    NProgress.done()
    return Promise.reject(error);
});

export default axios