import request from '../../util/request'
function select(data) {
    var url = ""
    if (data.keyWord === undefined || data.keyWord === null || data.keyWord === "") {
        url = '/article/select/' + data.articleTypeUnCode + '/' +data.pagesize + '/' + data.pagenum
    } else {
        url = '/article/select/' + data.articleTypeUnCode + '/' +data.pagesize + '/' + data.pagenum + '?keyword=' + data.keyWord
    }
    return request({

        url: url,
        method: 'get'
    })
}
function selectDetail(data) {
    return request({
        url: '/article/detail/select/' + data,
        method: 'get'
    })
}
function add(data) {
    return request({
        url: '/article/add',
        method: 'post',
        data
    })
}
function edit(data) {
    return request({
        url: '/article/edit',
        method: 'post',
        data
    })
}
function del(data) {
    return request({
        url: '/article/delete',
        method: 'post',
        data
    })
}
export {
    select,
    selectDetail,
    add,
    edit,
    del,
}