import { login,getVolidCode,select,selectBasicInfo,add,edit,del } from '../../api/user'
import store from '../../store'
const actions = {
    //用户登录
    login({ commit }, userInfo) {
        return new Promise((resolve, reject) => {
            login(userInfo).then(response => {
                const { data } = response
                if (!data.success) {
                    store.commit('removeTokenInfo')
                    reject(data)
                    
                }
                store.commit('setTokenInfo', { token: data.data.token })
                resolve(data) // 执行成功执行的函数，有了它调用该函数的方法才能使用then
            }).catch(error => {
                store.commit('removeTokenInfo')
                reject(error) // 执行失败执行的函数，有了它调用该函数的方法才能使用catch
            })
        })
    },
    //获取验证码
    getVolidCode({ commit }){
    	return new Promise((resolve, reject)=>{
    		getVolidCode().then(response=>{
    			const { data } = response
                if (!data.success) {
                    reject(data)
                }
                resolve(data) // 执行成功执行的函数，有了它调用该函数的方法才能使用then
          }).catch(error => {
                //store.commit('captcha')
                reject(error) // 执行失败执行的函数，有了它调用该函数的方法才能使用catch
    		})
    	})
    },
    //分页查询用户信息列表
    select({ commit },data){
        return new Promise((resolve, reject) => {
            select(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    //查询用户基本信息
    selectBasicInfo({ commit },data){
        return new Promise((resolve, reject) => {
            selectBasicInfo(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    //添加单个用户信息
    add({ commit },data){
        return new Promise((resolve, reject) => {
            add(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    //编辑用户信息
    edit({ commit },data){
        return new Promise((resolve, reject) => {
            edit(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    //删除单个用户信息
    del({ commit },data){
        return new Promise((resolve, reject) => {
            del(data).then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    //用户退出登录
    loginOut({ commit }) {
        const data = {
            success:true
        }
        return new Promise((resolve, reject) => {
            store.commit('removeTokenInfo')
                resolve(data)

        })
        
    },
}
export default {
    namespaced: true,
    actions
}