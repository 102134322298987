import request from '../../util/request'
function add(data) {
    return request({ 
        url: '/networkmodel/audit/add' ,
        method:'post',
        data
    })
}
export{
    add
}