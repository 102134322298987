import request from '../../util/request'
function selectCountOfUsers() {
    return request({ 
        url: '/data/display/select/count/of/users',
        method:'get'
    })
}
function selectCountOfMolecules() {
    return request({ 
        url: '/data/display/select/count/of/molecules',
        method:'get'
    })
}
function selectCountOfNetworkModels() {
    return request({ 
        url: '/data/display/select/count/of/network/models',
        method:'get'
    })
}
export{
    selectCountOfUsers,
    selectCountOfMolecules,
    selectCountOfNetworkModels
}