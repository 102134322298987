import request from '../../util/request'
//查询分子性质记录
function select(data) {
    return request({ 
        url: '/propertierecord/select' ,
        method:'post',
        data
    })
}
//添加分子性质记录
function add(data) {
    return request({ 
        url: '/propertierecord/add' ,
        method:'post',
        data
    })
}
//分页查询未审核分子性质记录信息列表
function selectNotAudit(data) {
    return request({ 
        url: '/propertierecord/notaudit/select/'+data.pagesize+'/'+data.pagenum ,
        method:'get'
    })
}
export{
    select,
    add,
    selectNotAudit
}