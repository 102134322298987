import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import '../theme/index.css' //引入自定义element样式
import './assets/index.css' //引入自定义样式
import './assets/icon/iconfont.css' //引入第三方图标
import './permission' //引入路由守卫
import locale from 'element-ui/lib/locale/lang/en' // 引入英文
import dataV from '@jiaminghi/data-view'

Vue.config.productionTip = false
Vue.use(ElementUI, { locale });
Vue.use(dataV);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
