import { selectCountOfUsers,selectCountOfMolecules,selectCountOfNetworkModels } from '../../api/dataDisplay'
const actions = {
    selectCountOfUsers({ commit }){
        return new Promise((resolve, reject) => {
            selectCountOfUsers().then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    selectCountOfMolecules({ commit }){
        return new Promise((resolve, reject) => {
            selectCountOfMolecules().then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    },
    selectCountOfNetworkModels({ commit }){
        return new Promise((resolve, reject) => {
            selectCountOfNetworkModels().then(res => {
                const {data} = res
                if(!data.success){
                    reject(data)
                }
                resolve(data)
            }).catch(error =>{
                reject(error)
            })
        })
    }
}
export default {
    namespaced: true,
    actions
}