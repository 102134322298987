import request from '../../util/request'
//添加分子性质审核记录
function add(data) {
    return request({ 
        url: '/propertierecordauditrecord/add' ,
        method:'post',
        data
    })
}
//分页查询已经审核分子性质记录
function selectAlreadyAudit(data) {
    return request({ 
        url: '/propertierecordauditrecord/alreadyaudit/select/'+data.pagesize+'/'+data.pagenum ,
        method:'get'
    })
}
export{
    add,
    selectAlreadyAudit
}